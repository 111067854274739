<template>
  <div>
    <overlay v-if="canOpen && !areAllComponentsMounted">
      <credpal-loader />
    </overlay>
    <div v-show="areAllComponentsMounted">
      <modal
        ref="form"
        :class-name="[
          'duration-100 flex flex-col gap-4 p-5 max-w-full w-full md:p-10',
          selectedUserId
            ? 'md:w-700px md:min-h-600px'
            : 'md:w-1000px md:min-h-full',
        ]"
        @mounted="registerMountedComponent('form')"
      >
        <div class="font-semibold mb-6 text-3xl">Enroll user for promo.</div>

        <UserSelection
          @mounted="registerMountedComponent('users')"
          @select="selectUser"
        />

        <form v-if="selectedUserId" @submit.prevent="submit">
          <form-group v-model="form.data.phone.value" :form="form" name="phone">
            Phone Number
          </form-group>

          <div v-if="getFormError(form)" class="alert alert-red mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>

          <button type="submit" :disabled="form.loading" class="btn btn-blue">
            <sm-loader-white v-if="form.loading" />
            <span>Submit</span>
          </button>
        </form>
      </modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromosAirtelFreeData',
  components: {
    UserSelection: () => import('../../../presentation/UserSelection.vue'),
  },
  data() {
    return {
      canOpen: false,
      mountedComponents: {
        form: false,
        users: false,
      },
      form: this.$options.basicForm([
        { name: 'phone', rules: 'required|phone' },
      ]),
      selectedUserId: null,
    };
  },
  computed: {
    areAllComponentsMounted() {
      return Object.keys(this.mountedComponents).every((key) => {
        return !!this.mountedComponents[key];
      });
    },
  },
  methods: {
    close() {
      this.$refs.form.close();
    },
    open() {
      this.canOpen = true;
      this.$refs.form.open();
    },
    selectUser(user) {
      this.selectedUserId = user?.id;
    },
    registerMountedComponent(componentName) {
      this.mountedComponents[componentName] = true;

      this.$nextTick(() => {
        if (this.areAllComponentsMounted) {
          this.$emit('mounted');
          if (this.shouldOpen) {
            this.open();
          }
        }
      });
    },
    async submit() {
      if (!this.validateForm(this.form)) return;
      
      this.form.loading = true;
      this.form.error = false;
      this.form.success = false;
      this.sendRequest('admin.promos.airtel.freeData.enroll', {
        data: {
          user_id: this.selectedUserId,
          ...this.getFormData(this.form),
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'The user will receive their free data shortly',
            button: 'Okay'
          });
          this.$emit('success');

        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      })
      .finally(() => {
        this.form.loading = false;
      });
    }
  },
};
</script>
